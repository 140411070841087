<template>
    <div class="blog-details-wrap ptb-100">
        <div class="container">
            <div class="row gx-5">
                <div class="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
                     <div>
                        <div class="post-img">
                            <img src="../../assets/images/blog/single-blog-1.jpg" alt="Image">
                        </div>
                        <div class="post-para">
                            
                            <div class="col-lg-12">
                                <div class="accordion style2" id="accordionExample">

                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                aria-expanded="true" aria-controls="collapseOne">
                                                <span>
                                                    <i class="ri-arrow-down-s-line plus"></i>
                                                    <i class="ri-arrow-up-s-line minus"></i>
                                                </span>
                                                WHAT IS A MORTGAGE LOAN?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show"
                                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="single-product-text">
                                                    <p> 
                                                        By simple definition, a mortgage is a type of loan you can use to buy refinance or remodel a home, a way to buy property without having all 
                                                        of the cash up front. Thus, a mortgage loan is a loan for which a property serves as collateral by notarial deed. The basis for a mortgage 
                                                        loan is the collateral and security in which the lender has to claim repayment of the particular loan.
                                                    </p>
                                                    <p>
                                                        There is such a thing as a right of mortgage, and this claim is surely privileged by it and established by notarial deed. 
                                                        This empowers the lender to recover a loan from the proceeds of the sale of the property by priority, therefore before other creditors. 
                                                        The owner of a property that confers the right of mortgage is known as the mortgagor. His lender, PFTSA, who is granted the right of mortgage, is known as the mortgage holder.
                                                    </p>
                                                    <p>
                                                        A mortgage is a loan provided by a mortgage lender or bank that enables an individual to purchase a home or property. 
                                                        It is possible to take out loans that cover the entire cost of a home at the execution value, yet it’s more common to secure a loan for about 80% of the home or property’s value, known as the market value.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                aria-expanded="false" aria-controls="collapseTwo">
                                                <span>
                                                    <i class="ri-arrow-down-s-line plus"></i>
                                                    <i class="ri-arrow-up-s-line minus"></i>
                                                </span>
                                                TYPES OF MORTGAGES
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse "
                                            aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>
                                                    The PFTSA only provides Fixed-rate mortgages. This is a loan that has a fixed interest rate for the entire term of the loan, carrying a constant interest rate from beginning to end.
                                                </p>
                                                <br>
                                                <p>
                                                    <strong>A PFTSA mortgage can be requested for:</strong>
                                                    <ul class="content-feature-list style2 list-style mt-15" style="padding-left: 25px;">
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Purchase of lot with the construction of a house turnkey.</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Expansion or renovation of a house.</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Construction of Apartment [must be on owned residential land].</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Construction of house.</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Purchase of existing home.</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Takeover existing mortgage loan.</p>
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingThree">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                aria-expanded="false" aria-controls="collapseThree">
                                                <span>
                                                    <i class="ri-arrow-down-s-line plus"></i>
                                                    <i class="ri-arrow-up-s-line minus"></i>
                                                </span>
                                                MORTGAGE REQUIREMENTS
                                            </button>
                                        </h2>
                                        <div id="collapseThree" class="accordion-collapse collapse"
                                            aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p>
                                                    <strong>To be eligible for the PFTSA mortgage, we request for all of the following:</strong>
                                                    <ul class="content-feature-list style2 list-style mt-15" style="padding-left: 25px;">
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Appraisal Report under 1 year; must include “Complete Inzage”.</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Purchase Agreement (if buying).</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Recent job recommendation letter.</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Pay slips of the last 3 months.</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>If self-employed: last 3 yearly financial statements + registration at Chamber of Commerce + Tax office declaration of last 3 years.</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Valid picture form of identification.</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Residents with work permits must have minimum of 5 permits and the last permit obtained.</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Same documents for spouse or partner.</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Proof of address (awg. 5,- censo paper) or utility bill on client’s name.</p>
                                                        </li>
                                                    </ul>
                                                </p>

                                                <p>
                                                    <strong>Requirements for construction / renovation</strong>
                                                    <ul class="content-feature-list style2 list-style mt-15" style="padding-left: 25px;">
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Same as above, plus.</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Construction agreement.</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>All Risk construction insurance.</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Surveying cost (included in the loan or optional for the client to pay).</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Quotation of materials and labor signed by constructor and client (in at least 5 phases and max 7).</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Cleaning lot / Walls / Roof / Plaster, windows, electricity / Ceiling, Tiles /Sanitary, kitchen (optional) & painting.</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Copy of Constructor’s chamber of commerce.</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>ID(s) of Construction company Director.</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>2 reference names for the contractor. Mentioning name, phone number and address.</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Building permit & Building plans (approved by DOW).</p>
                                                        </li>
                                                    </ul>
                                                </p>

                                                <p>
                                                    <strong>Required insurances:</strong>
                                                    <ul class="content-feature-list style2 list-style mt-15" style="padding-left: 25px;">
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Insurances: Life / Personal Accident / Fire all inclusive.</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>All Risk construction insurance Costs related to the loan.</p>
                                                        </li>
                                                    </ul>
                                                </p>

                                                <p>
                                                    <strong>Additional Cost:</strong>
                                                    <ul class="content-feature-list style2 list-style mt-15" style="padding-left: 25px;">
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Closing Fee of 1.25% (included in the loan or optional for the client to pay).</p>
                                                        </li>
                                                        <li>
                                                            <i class="flaticon-checkbox"></i>
                                                            <p>Notary charges & “Overdrachtskosten”/Property tax (included in the loan or optional for the client to pay).</p>
                                                        </li>
                                                    </ul>
                                                </p>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingfour">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapsefour"
                                                aria-expanded="true" aria-controls="collapsefour">
                                                <span>
                                                    <i class="ri-arrow-down-s-line plus"></i>
                                                    <i class="ri-arrow-up-s-line minus"></i>
                                                </span>
                                                CONSTRUCTION TIPS
                                            </button>
                                        </h2>
                                        <div id="collapsefour" class="accordion-collapse collapse "
                                            aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="single-product-text">
                                                    <p>
                                                        Are you constructing your new home? Here are some valuable construction tips you should keep in mind:
                                                    </p>
                                                    <ol class="content-feature-list style2 list-style mt-15">
                                                        <li>
                                                            <p>1. Have a savings plan.</p>
                                                        </li>
                                                        <li>
                                                            <p>2. Leave room in the budget for additional construction costs (aka Contingency Budget).</p>
                                                        </li>
                                                        <li>
                                                            <p>3. Find your own Surveyor, Independent from the Funds-Surveyor. This person will assist you in evaluating the quality of your build.</p>
                                                        </li>
                                                        <li>
                                                            <p>4. Determine which materials you want to use. Think on sustainability.</p>
                                                        </li>
                                                        <li>
                                                            <p>5. Do not make major changes on building plans after DOW approval, as this will delay your approvals process and ultimately delay your move-in date.</p>
                                                        </li>
                                                        <li>
                                                            <p>6. Be patient seeking out the right construction company/contractor. Request a proposal of at least three constructors, analyze them thoroughly. Be aware of extra work items and deductions.</p>
                                                        </li>
                                                        <li>
                                                            <p>7. Understand the contract you are signing with the builder.</p>
                                                        </li>
                                                        <li>
                                                            <p>8. Plan for delays.</p>
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingfive">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapsefive"
                                                aria-expanded="true" aria-controls="collapsefive">
                                                <span>
                                                    <i class="ri-arrow-down-s-line plus"></i>
                                                    <i class="ri-arrow-up-s-line minus"></i>
                                                </span>
                                                TERMS & CONDITIONS
                                            </button>
                                        </h2>
                                        <div id="collapsefive" class="accordion-collapse collapse "
                                            aria-labelledby="headingfive" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="single-product-text">
                                                    <p>
                                                        The PFTSA offers a long term annuity mortgage at a competitive interest rate and in line with our current conditions the mortgagor is eligible for 
                                                        a maximum term of the loan of 30 years or up to his 65th birthday, provided sufficient pension income when applicable and proper life insurance coverage.
                                                    </p>
                                                    <p>
                                                        Generally you can borrow up to 80% of the market value with PFTSA of the mortgaged property as mentioned in an appraisal report, all depending on the 
                                                        different aspects of the case. The appraisal report cannot be older than one year and should be compiled by an appraiser on the list of PFTSA.
                                                    </p>
                                                    <p>
                                                        The right of mortgage can only be established by a civil law notary. The mortgage deed then states the maximum amount that the bank can recover 
                                                        in case of default. As for the loan agreement or credit agreement they state the amount that is made available to the mortgagor. This credit can 
                                                        be made up or even increased as long as the maximum amount of the mortgage deed is not exceeded. Through such arrangement the procedure at the 
                                                        notary and costs involved would not require a repetition.
                                                    </p>
                                                    <p>
                                                        In the case that the mortgagor goes in default of compliance with amortization of the mortgage, the bank, in his capacity of mortgage holder, is entitled to take recourse by selling the mortgaged property at public auction. In order to recover, by priority above others, his claim on the mortgagor out of the proceeds of same.
                                                    </p>
                                                    <p>
                                                        Please note that these terms and conditions are subject to change without prior notice. The above terms and conditions therefore do not constitute an offer.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                                                        <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingfive">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapsefive"
                                                aria-expanded="true" aria-controls="collapsefive">
                                                <span>
                                                    <i class="ri-arrow-down-s-line plus"></i>
                                                    <i class="ri-arrow-up-s-line minus"></i>
                                                </span>
                                                TERMS & CONDITIONS
                                            </button>
                                        </h2>
                                        <div id="collapsefive" class="accordion-collapse collapse "
                                            aria-labelledby="headingfive" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="single-product-text">
                                                    <p>
                                                        First of all, you need to be employed by a company that is affiliated through a pension plan to PFTSA. The decision to build or buy your 
                                                        first home is one that the mortgagor  most often desires to make in due consideration of the consequences. The individual needs of the customer 
                                                        comes first, of which we are more than happy to support our customers with such big decisions. At PFTSA we are with you from start to finish, 
                                                        every step of the way.
                                                    </p>
                                                    <p>
                                                        We have simplified the application for a mortgage loan by developing an application form that guides and supports the applicants in their requests. 
                                                        Ask for this form at our desk or download it from this website. You are also welcome to make an appointment to learn more about the many possibilities 
                                                        for a personal mortgage. Once all required documents are received, we hand down an approval within 10-15 working days. 
                                                        We then make you an offer which upon acceptance you begin the arrangement of your insurances. After this process, the mortgage deed can be consummated.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyMortage'
}
</script>