<template>
    <div class="terms-wrap pt-100 pb-80">
        <div class="container">
            <div class="row gx-5">
                <div class="col-lg-12">

                    <div class="single-terms">
                        <h3>Information Collection</h3>
                        <p>
                            On this website, we collect non-personal information such as navigation data, including the pages you visit, 
                            the time you spend on them, and how you interact with the content. We do not collect personal information 
                            directly, as the website is purely informative and does not contain a login.
                        </p>
                    </div>

                    <div class="single-terms mt-50">
                        <h3>How We Use Cookies</h3>
                        <p>
                            We use cookies to improve user experience and analyse traffic on our website. 
                            Cookies are small files that are stored on your device while you browse. 
                            They allow us to optimise navigation, deliver relevant content and better understand user behaviour. 
                            You can choose to disable cookies in your browser settings, although this may affect the functionality of some parts of the site.
                        </p>
                    </div>

                    <div class="single-terms mt-50">
                        <h3>The Collection, Process, and Use of Personal Data</h3>
                        <p>
                            Since this website does not require user registration or the entry of personal information, we do not process or store personal data. 
                            However, should you decide to contact us through any external means, such as email or telephone, the data provided will be treated in 
                            accordance with current data protection regulations.
                        </p>
                    </div>

                    <div class="single-terms mt-50">
                        <h3>Data Protection</h3>
                        <p>
                            We are committed to protecting the information we collect from our visitors by using industry-standard security 
                            technologies and procedures.  If at any time we begin to collect personal data, it will be treated with the utmost 
                            care and confidentiality, in accordance with applicable regulations.
                        </p>
                    </div>

                    <div class="single-terms mt-50">
                        <h3>Our Policy For Age Under 18</h3>
                        <p>
                            The content of this site is intended for a general audience. We do not knowingly solicit personal information from anyone under the age of 18. In the event that we become aware that we have inadvertently received personal information from a minor, we will take steps to securely delete such information from our records.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy'
}
</script>