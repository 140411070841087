import { createWebHistory, createRouter } from "vue-router";

import HomePage from "../components/Pages/HomePage";
import WhoWeArePage from "../components/Pages/WhoWeArePage";
import MyPensionPage from "../components/Pages/MyPensionPage";
import MyMortagePage from "../components/Pages/MyMortagePage";
import AnnualReportsPage from "../components/Pages/AnnualReportsPage";
import ContactUsPage from "../components/Pages/ContactUsPage";

import FaqPage from "../components/Pages/FaqPage";
import TestimonialsPage from "../components/Pages/TestimonialsPage";
import TermsOfServicePage from "../components/Pages/TermsOfServicePage";
import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
import ErrorPage from "../components/Pages/ErrorPage";

const routes = [
  { path: "/", name: "HomePage", component: HomePage },
  { path: "/who-we-are", name: "WhoWeAre", component: WhoWeArePage },
  { path: "/my-pension", name: "MyPensionPage", component: MyPensionPage },
  { path: "/my-mortage", name: "MyMortagePage", component: MyMortagePage },
  { path: "/annual-reports", name: "AnnualReportsPage", component: AnnualReportsPage },

  { path: "/faq", name: "FaqPage", component: FaqPage },
  {
    path: "/testimonials",
    name: "TestimonialsPage",
    component: TestimonialsPage,
  },
  {
    path: "/terms-of-service",
    name: "TermsOfServicePage",
    component: TermsOfServicePage,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
  { path: "/contact", name: "ContactUsPage", component: ContactUsPage },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
