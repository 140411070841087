<template>
  <footer class="footer-wrap bg-rock">
    <div class="container">
      <div class="row pt-100 pb-75">

        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
          <div class="footer-widget">
            <router-link to="/" class="footer-logo">
              <img src="../../assets/images/temp-logo.png" alt="Image" />
            </router-link>
            <p class="comp-desc">
              PFTSA strives to be the number one choice of employers and employees for the execution of their retirement plans.
            </p>
            <div class="social-link">
              <ul class="social-profile list-style style1">
                <li>
                  <a target="_blank" href="https://facebook.com">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://twitter.com">
                    <i class="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.linkedin.com/company/pension-fund-tourism-sector-aruba/">
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://instagram.com">
                    <i class="ri-pinterest-fill"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">Useful links</h3>
            <ul class="footer-menu list-style">

                  <li>
                    <router-link to="/faq">
                      <i class="flaticon-next"></i> FAQ
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/testimonials">
                      <i class="flaticon-next"></i> Testimonials
                    </router-link>
                  </li>

                  <li>
                    <router-link to="/terms-of-service">
                      <i class="flaticon-next"></i> Terms of Service
                    </router-link>
                  </li>

                  <li>
                    <router-link to="/privacy-policy">
                      <i class="flaticon-next"></i> Privacy Policy
                    </router-link>
                  </li>

            </ul>
          </div>
        </div>

        <div class="col-xl-4 col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">Subscribe</h3>
            <p class="newsletter-text">
              Tablished fact that a reader will be distrated by the readable
              content
            </p>
            <form @submit.prevent class="newsletter-form">
              <input type="email" autocomplete="off" placeholder="Your Email" />
              <button type="button">Subscribe</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-text">
      <p>
        <i class="ri-copyright-line"></i>{{ currentYear }} Handcrafted by
        <a href="https://qwihi.com/" target="_blank">Qwihi Innovations</a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
