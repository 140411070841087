<template>

    <div class="security-wrap pt-100 pb-0">
        <div class="container">
            <div class="row gx-5 align-items-center">

                <div class="col-lg-6 col-12 order-lg-1 order-1">
                    <div class="security-content">
                        <div class="content-title style1">
                            <span></span>
                            <h2>Vision</h2>
                            <p>
                                PFTSA strives to be the number one choice of employers and employees for the execution of their retirement plans.
                                <br>
                                As a PFTSA member/participant you are entitled to apply for a mortgage loan at an attractive interest rate.
                                <br>
                                At PFTSA we do care about the financial well being of our members/participants, not just their pension.
                            </p>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-6 col-12 order-lg-2 order-2">
                    <div class="security-content">
                        <div class="content-title style1">
                            <span></span>
                            <h2>Mission</h2>
                            <p>
                                PFTSA is dedicated to providing retirement and related benefits, accurate information, dependable communication, and valuable educational assistance to its members. 
                                <br>
                                As responsible fiduciaries, we will professionally manage the resources of PFTSA and implement its practices, plans, and benefit services with the highest ethical standards.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="team-wrap pt-100 pb-75">
        <div class="container">
            <div class="row gx-5 align-items-center mb-50">
                <div class="content-title style1">
                    <h2 style="text-align: center;">Board Members</h2>
                </div>
            </div>
            <div class="row justify-content-center">

                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="team-card style1">
                        <div class="team-img">
                            <img class="force-responsive-img" src="../../assets/images/team/Glenn-Farro.jpg" alt="Image">
                            <ul class="social-profile list-style style1">
                                <li>
                                    <a target="_blank" href="https://facebook.com">
                                        <i class="ri-facebook-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://linkedin.com">
                                        <i class="ri-linkedin-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://twitter.com">
                                        <i class="ri-twitter-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://pinterest.com">
                                        <i class="ri-pinterest-fill"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="team-info">
                            <h3>Glenn Farro</h3>
                            <span>Chairman</span>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="team-card style1">
                        <div class="team-img">
                            <img class="force-responsive-img" src="../../assets/images/team/Thais-2-1017x1536.jpg" alt="Image">
                            <ul class="social-profile list-style style1">
                                <li>
                                    <a target="_blank" href="https://facebook.com">
                                        <i class="ri-facebook-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://linkedin.com">
                                        <i class="ri-linkedin-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://twitter.com">
                                        <i class="ri-twitter-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://pinterest.com">
                                        <i class="ri-pinterest-fill"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="team-info">
                            <h3>Thaïs Nierop - Kappel</h3>
                            <span>Employer's Representative</span>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="team-card style1">
                        <div class="team-img">
                            <img class="force-responsive-img" src="../../assets/images/team/picture-template.jpg" alt="Image">
                            <ul class="social-profile list-style style1">
                                <li>
                                    <a target="_blank" href="https://facebook.com">
                                        <i class="ri-facebook-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://linkedin.com">
                                        <i class="ri-linkedin-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://twitter.com">
                                        <i class="ri-twitter-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://pinterest.com">
                                        <i class="ri-pinterest-fill"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="team-info">
                            <h3>Eugene Dirksz</h3>
                            <span>Employee's Representatives</span>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="team-card style1">
                        <div class="team-img">
                            <img class="force-responsive-img" src="../../assets/images/team/Paul-2-1017x1536.jpg" alt="Image">
                            <ul class="social-profile list-style style1">
                                <li>
                                    <a target="_blank" href="https://facebook.com">
                                        <i class="ri-facebook-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://linkedin.com">
                                        <i class="ri-linkedin-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://twitter.com">
                                        <i class="ri-twitter-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://pinterest.com">
                                        <i class="ri-pinterest-fill"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="team-info">
                            <h3>Paul Vandormael</h3>
                            <span>Employee's Representatives</span>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="team-card style1">
                        <div class="team-img">
                            <img class="force-responsive-img" src="../../assets/images/team/Ernesto-Albertus.jpg" alt="Image">
                            <ul class="social-profile list-style style1">
                                <li>
                                    <a target="_blank" href="https://facebook.com">
                                        <i class="ri-facebook-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://linkedin.com">
                                        <i class="ri-linkedin-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://twitter.com">
                                        <i class="ri-twitter-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://pinterest.com">
                                        <i class="ri-pinterest-fill"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="team-info">
                            <h3>Ernesto Albertus</h3>
                            <span>Employer’s Representative</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<style>
.force-responsive-img{
    max-height: 400px !important;
    width: 100% !important;
}
</style>
<script>
export default {
    name: 'WhoWeAre'
}
</script>