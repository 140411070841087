<template>
    <div class="blog-wrap ptb-100">
        <div class="container">
            <div class="row justify-content-center">

                <div class="col-xl-4 col-lg-6 col-md-6" v-for="item in annualReportItems" :key="item.year">
                    <div class="blog-card style1">
                        <div class="blog-img">
                            <a :href="`../../assets/docs/annual-reports/PFTSA-Annual-Report-${item.year}.pdf`">
                                <img src="../../assets/images/annual-reports/img-template.jpg" alt="Image">
                            </a> 
                        </div>
                        <div class="blog-info">
                            <h3>
                                <a :href="`../../assets/docs/annual-reports/PFTSA-Annual-Report-${item.year}.pdf`">Annual Report {{ item.year }}</a>
                            </h3>
                            <a :href="`../../assets/docs/annual-reports/PFTSA-Annual-Report-${item.year}.pdf`">
                                Read more 
                                <i class="flaticon-right-arrow"></i>
                            </a>  
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AnnualReports',
    data: () => ({
    annualReportItems: [
        {
            year: 2023
        },
        {
            year: 2022
        },
        {
            year: 2021
        },
        {
            year: 2020
        },
        {
            year: 2019
        }
    ],
  }),
}
</script>