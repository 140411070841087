<template>
    <div class="security-wrap ptb-100">
        <div class="container" id="Benefits">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6 col-12 order-lg-1 order-2">
                    <div class="security-content">
                        <div class="content-title style1">
                            <span></span>
                            <h2>Our Benefits</h2>
                            <p></p>
                        </div>
                        <div class="feature-item-wrap">
                            <div class="feature-item">
                                <div class="feature-icon">
                                    <router-link style="display: flex;" to="/my-pension">
                                        <img src="../../assets/images/security/security-icon-2.png" alt="Image">
                                    </router-link>
                                </div>
                                <div class="feature-text">
                                    <h3>
                                        <router-link to="/my-pension">Pension</router-link>
                                    </h3>
                                    <p>
                                        As a current participant, you’re already on the right track to ensuring a secure and financially stable future. 
                                        Are you interested in reviewing your progress? Just check out your account information! 
                                        We are also more than happy to share exclusive investment news with you.
                                    </p>
                                </div>
                            </div>
                            <div class="feature-item">
                                <div class="feature-icon">
                                    <router-link style="display: flex;" to="/my-pension">
                                        <img src="../../assets/images/security/security-icon-2.png" alt="Image">
                                    </router-link>
                                </div>
                                <div class="feature-text">
                                    <h3>
                                        <router-link to="/my-mortage">Mortages</router-link>
                                    </h3>
                                    <p>
                                        We help you ensure a FUND-filled future, but we can also assist in making your real estate dreams come true. 
                                        Our pension contributors have the unique opportunity to receive financing assistance for mortgages.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12 order-lg-2 order-1">
                    <div class="security-img-wrap">

                        <img src="../../assets/images/security/security-1.png" alt="Image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Benefits'
}
</script>