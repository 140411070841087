<template>
  <div class="hero-wrap style1 bg-spring">
    <div class="hero-slider-two">
      <carousel>
        <Slide v-for="slide in carouselItems" :key="slide.id">
          <div class="hero-slide-item">
            <div class="container-fluid">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div class="hero-content">
                    <span>{{ slide.subtitle }}</span>
                    <h1>{{ slide.heading }}</h1>
                    <p>{{ slide.desc }}</p>
                    <button class="btn style1" onclick="SwipeToSection('Benefits')">View Benefits</button>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="hero-img-wrap">
                    <img style="border-radius: 15px !important; max-height: 60vh;" :src="slide.image" alt="Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slide>

        <!-- <template #addons>
          <navigation>
            <template #next>
              <i class="flaticon-next"></i>
            </template>
            <template #prev>
              <i class="flaticon-left-arrow-1"></i>
            </template>
          </navigation>
        </template> -->
      </carousel>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  Carousel,
  Slide,
  // Navigation
} from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "MainBanner",
  components: {
    Carousel,
    Slide,
    // Navigation,
  },
  data: () => ({
    backgroundImg: require("../../assets/images/slider/background.png"),
    carouselItems: [
      {
        id: 1,
        subtitle: "",
        heading: "Pension Fund Tourism Sector Aruba",
        desc: "PFTSA strives to be the number one choice of employers and employees for the execution of their retirement plans.",
        image: require("../../assets/images/slider/slide.png"),
      },
    ],
  }),
});
</script>
