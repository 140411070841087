<template>
    <div class="blog-details-wrap ptb-100">
        <div class="container">
            <div class="row gx-5">
                <div class="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
                    <div>
                        <div class="post-img">
                            <img src="../../assets/images/blog/single-blog-1.jpg" alt="Image">
                        </div>
                        <h2 style="text-align: center;">Benefits</h2>
                        <div class="post-para">
                            <!-- <blockquote class="wp-block-quote">
                                <span class="wp-quote-icon"><i class="flaticon-quotation-mark"></i></span>
                                <div class="wp-quote-text">
                                    <p>"Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed cum fuga ab, eum numquam ea dolores quae takimata sanctus est Lorem amet ducimus Lorem ipsum, dolor sit amet consectetur adipisicing amet dolor"</p>
                                    <h6>Mal Practive</h6>
                                </div>
                            </blockquote> -->
                            
                            <h4 class="mt-40">PFTSA offers a defined contribution plan:</h4>
                            <p>6% of the gross salary > 10% administration costs > Interest > Old age pension + widow/er pension [optional].</p>

                            <h4 class="mt-50">At PFTSA, we provide essential flexibility for both employers and employees:</h4>
                            <ul class="content-feature-list style2 list-style mt-15">
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    Employers can contribute with a higher percentage [maximum total 25%].
                                </li>
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    Employees can contribute extra through a lumpsum payment.
                                </li>
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    Retire earlier [not earlier than at the age of 60].
                                </li>
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    Retire later [maximum until the age of 70].
                                </li>
                            </ul>
                            
                            <h4 class="mt-50">EMPLOYEE</h4>
                            <ul class="content-feature-list style2 list-style mt-15">
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    Administration costs: 10%.
                                </li>
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    <strong>NO</strong> extra insurances are included, so no extra costs are needed.
                                </li>
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    PFTSA is a foundation, which means:
                                    <ul class="mt-10">
                                        <li>
                                            <i class="flaticon-checkbox"></i>
                                            <strong>NO</strong> profit tax
                                        </li>
                                        <li>
                                            <i class="flaticon-checkbox"></i>
                                            <strong>NO</strong> shareholders
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p>The above makes way for a chance of higher interest.</p>

                            <h4 class="mt-50">EMPLOYER</h4>
                            <ul class="content-feature-list style2 list-style mt-15">
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    The pension premiums booked by PFTSA will always match the payroll of the company
                                </li>
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    Period of 15 days to pay the pension premium without any extra charge.
                                </li>
                            </ul>

                            <h4 class="mt-50">EMPLOYEE/EMPLOYER</h4>
                            <ul class="content-feature-list style2 list-style mt-15">
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    Small organization
                                </li>
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    Extra attention to the client.
                                </li>
                            </ul>

                            <h4 class="mt-50">SWITCHING PENSION PROVIDER</h4>
                            <ul class="content-feature-list style2 list-style mt-15">
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    2/3 part of the employees must agree with the switch.
                                </li>
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    Two options for the accumulated pension capital at the previous pension provider:
                                    <ul class="mt-10">
                                        <li>
                                            <i class="flaticon-checkbox"></i>
                                            Transfer the accumulated pension capital to the new pension provider Cost: Afl. 250.00
                                        </li>
                                        <li>
                                            <i class="flaticon-checkbox"></i>
                                            leave the accumulated pension capital at the previous pension provider will receive at retirement age pension of both pension providers
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <h4 class="mt-50 mb-10">RESIGNATION</h4>
                            Vested policy.
                            <br>
                            You will still earn interest annually and, at retirement date, will receive your pension.
                            <ul class="content-feature-list style2 list-style mt-15">
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    Transfer value to another insurance company.
                                </li>
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    Emigration -> Cash out the accrued capital after three years living abroad.
                                </li>
                            </ul>

                            <h4 class="mt-50 mb-10">DEATH</h4>
                            Married and children under 21 or 27 years studying and/or disabled: Accrued capital will be used to buy a partner pension and orphan pension.
                            <ul class="content-feature-list style2 list-style mt-15">
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    Single and children under 21 or 27 years studying and/or disabled: Accrued capital will be used to buy an orphan’s pension.
                                </li>
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    Single and no children under 21 or 27 years studying and/or disabled: 
                                    <br> 
                                        70% of the accrued capital will be paid out as a lump sum to the beneficiaries as stipulated on the Beneficiary Form.
                                </li>
                            </ul>

                            <h4 class="mt-50 mb-10">DIVORCE</h4>
                            <ul class="content-feature-list style2 list-style mt-15">
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    The accumulated capital during the marriage is part of the estate and will be divided in half.
                                </li>
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    Can deviate from this via a notary (divorce settlement), or the ex-partner can sign a waiver.}
                                </li>
                            </ul>

                        </div>
                    </div>

                    <!-- <div class="post-meta-option">
                        <div class="row gx-0 align-items-center">
                            <div class="col-md-5 col-12 text-md-end text-start">
                                <div class="post-share w-100">
                                    <span>Share:</span>
                                    <ul class="social-profile style2 list-style">
                                        <li>
                                            <a target="_blank" href="https://facebook.com">
                                                <i class="ri-facebook-fill"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="https://twitter.com">
                                                <i class="ri-twitter-fill"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="https://linkedin.com">
                                                <i class="ri-linkedin-fill"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="https://instagram.com">
                                                <i class="ri-pinterest-fill"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyPension'
}
</script>