<template>
    <div class="page-wrapper">
        <Navbar class="style1" />
        <div class="content-wrapper">
            <PageTitle pageTitle="My Mortage" />
            <MyMortage />
        </div>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import MyMortage from '../MyMortage/MyMortage';
import Footer from '../Layouts/Footer'

export default {
    name: 'MyMortagePage',
    components: {
        Navbar,
        PageTitle,
        MyMortage,
        Footer,
    }
}
</script>