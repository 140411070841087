<template>
    <div style="height: 100vh !important; width: 100% !important; display: flex; justify-content: center; align-items: center;">
        <img src="../../assets/images/temp-logo.png" alt="">
    </div>
    <!-- <div class="loader js-preloader">
        <div></div>
        <div></div>
        <div></div>
    </div> -->
</template>

<script>
export default {
    name: 'Preloader'
}
</script>