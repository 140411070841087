<template>
  <div :class="['header-wrap', { sticky: isSticky }]">
    <!-- Top Header -->
    <div class="header-top" :class="{ open: open }">
      <button type="button" class="close-sidebar" @click="open = !open">
        <i class="ri-close-fill"></i>
      </button>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-9">
            <div class="header-top-left">
              <ul class="contact-info list-style">
                <li>
                  <i class="flaticon-call"></i>
                  <a href="tel:(297) 582-4499">(297) 582-4499</a>
                </li>
                <li>
                  <i class="flaticon-email-1"></i>
                  <a href="mailto:info@pftsa.com">info@pftsa.com</a>
                </li>
                <li>
                  <i class="flaticon-pin"></i>
                  <p>Avenida E.J. “Watty” Vos 24, Second Floor.</p>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-4 col-md-3">
            <div class="header-top-right">
              <ul class="header-top-menu list-style">
                <li><router-link to="/contact">Help</router-link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Top Header -->

    <!-- Navbar -->
    <div class="header-bottom">
      <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
          <router-link class="navbar-brand" to="/">
            <img class="logo-light" style="height: 73px;" src="../../assets/images/temp-logo.png" alt="logo" />
            <img class="logo-dark" style="height: 73px;" src="../../assets/images/temp-logo.png" alt="logo" />
          </router-link>

          <div class="collapse navbar-collapse" :class="{ show: active }">
            <ul class="navbar-nav ms-auto">
              
              <li class="nav-item has-dropdown">
                <router-link to="/" class="nav-link">Start Planning</router-link>
              </li>

              <li class="nav-item">
                <router-link to="/who-we-are" class="nav-link">Who We Are</router-link>
              </li>

              <li class="nav-item">
                <router-link to="/my-pension" class="nav-link">My Pension</router-link>
              </li>

              <li class="nav-item">
                <router-link to="/my-mortage" class="nav-link">My Mortage</router-link>
              </li>

              <li class="nav-item has-dropdown">
                <router-link to="/annual-reports" class="nav-link">Annual Reports</router-link>
              </li>

              <li class="nav-item">
                <router-link to="/contact" class="nav-link">Contact us</router-link>
              </li>

            </ul>

          </div>

          <div class="mobile-bar-wrap">
            <!-- <div class="mobile-sidebar" @click="open = !open" :aria-pressed="open ? 'true' : 'false'"
              v-bind:class="{ open: button_open_state }" v-on:click="button_open_state = !button_open_state">
              <i class="ri-menu-4-line"></i>
            </div> -->
     
            <div class="navbar-toggler mobile-menu xl-none" @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
              v-bind:class="{ active: button_active_state }"
              v-on:click="button_active_state = !button_active_state">
              <a href="javascript:void(0);">
                <i class="ri-menu-line"></i>
                <i class="ri-close-line"></i>
              </a>
            </div>
          </div>
        </nav>
      </div>

      <!-- Search Modal -->
      <div class="search-area" :class="{ search: search }">
        <div class="container">
          <form @submit.prevent>
            <div class="form-group">
              <input type="search" placeholder="Search Here" autofocus />
            </div>
          </form>
          <button type="button" class="close-searchbox" @click="search = !search">
            <i class="ri-close-line"></i>
          </button>
        </div>
      </div>

    </div>
    <!-- End Navbar -->
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data: () => ({
    isSticky: false,
    active: false,
    button_active_state: false,
    search: false,
    button_search_state: false,
    open: false,
    button_open_state: false,
  }),
  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = true;
      } else {
        that.isSticky = false;
      }
    });
  },
};
</script>
