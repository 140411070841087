<template>
    <div class="page-wrapper">
        <Navbar class="style1" />
        <MainBanner />
        <Benefits />
        <Testimonials />
        <AppDownload />
        <!-- <ContactUs /> -->
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../HomePage/MainBanner'
import Benefits from '../HomePage/Benefits'
import Testimonials from '../HomePage/Testimonials'
import AppDownload from '../HomePage/AppDownload'
// import ContactUs from '../HomePage/ContactUs'
import Footer from '../Layouts/Footer'

export default {
    name: 'HomePage',
    components: {
        Navbar,
        MainBanner,
        Benefits,
        Testimonials,
        AppDownload,
        // ContactUs,
        Footer,
    }
}
</script>